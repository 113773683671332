import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { MsalProvider } from "@azure/msal-react";
import { Configuration,  PublicClientApplication } from "@azure/msal-browser";

const config: Configuration = {
    auth: {
      clientId: "edb957d0-6ea7-41bb-997d-71c7f1525d8d",
      authority: "https://login.microsoftonline.com/6029eb4a-169e-427b-9542-3ddbd53988f6"
    }
  };

const instance = new PublicClientApplication(config);

const AppWrapper = () => (
    <MsalProvider instance={instance}>
        <App />
    </MsalProvider>
)


ReactDOM.render(<AppWrapper />, document.getElementById('root'));
