import React, { useEffect } from 'react';
import Retool from 'react-retool';
import { InteractionType, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from '@azure/msal-react';

function App() {

  const { login, error } = useMsalAuthentication(InteractionType.Silent, {});

  const { inProgress } = useMsal();

  useEffect(() => {

    if (error instanceof InteractionRequiredAuthError && inProgress === InteractionStatus.None) {

      login(InteractionType.Redirect, {}).then(() => console.log("login success"));

    }

  }, [login, error, inProgress]);

  const { accounts } = useMsal();

  return (
    <React.Fragment>
        <AuthenticatedTemplate>
            <Retool
            url="https://hopsndrops.retool.com/embedded/public/d389fad0-5667-4445-9982-9a8eb4f85636"
            data={({data:accounts?.[0]})}
            onData={({})}
            />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <p class="signin">Signing in...</p>
        </UnauthenticatedTemplate>
    </React.Fragment>
  );

}

export default App;
